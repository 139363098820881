<template>
  <div class="form--group row">
    <label class="col-12 col-lg-3">Requirements</label>
    <div class="col-12 col-lg-6">
      <requirement-field
        v-for="(requirement, index) in requirements"
        :key="index"
        :index="index"
        :requirement="requirement"
        ref="requirementField"
        :requirementLength="requirements.length"
        placeholder="Requirements"
      />
      <div class="col-12 p-0 mt-2">
        <v-btn color="primary" @click="addRequirement" type="button" id="requirementBtn">
          Tambah Requirement
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
const RequirementField = () =>
  import('@/components/promo-management/promo/form/requirements/requirement-field');
import { mapState } from 'vuex';

export default {
  name: 'nearby-places-section',
  components: {
    RequirementField,
  },
  computed: {
    ...mapState({
      requirements: (state) => state.promo.form.requirements,
    }),
  },
  methods: {
    async validate() {
      if (this.$refs.requirementField) {
        const requirementValidation = this.$refs.requirementField.map(function (form) {
          return form.$validate();
        });
        const results = await Promise.all(requirementValidation);
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      }
    },
    addRequirement() {
      this.$store.commit('promo/form/ADD_REQUIREMENTS');
    },
  },
};
</script>

<style scoped></style>
